import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
// const baseUrl = "http://18.188.165.80:3000";
// const baseUrl = "http://localhost:3000";
// const baseUrl = "http://192.168.1.121:3000"
// const baseUrl = "http://192.168.2.212:3001"
// const baseUrl ="http://138.197.86.118:3001"
// const baseUrl = "https://api.big4healthapp.com"; 
// const baseUrl ="http://192.168.0.107:3001"   // local netset
// const baseUrl ="http://192.168.2.39:3000"  // local airtel netset
// const baseUrl = "https://staging-api.big4healthapp.com"  // staging
// const baseUrl ="https://big4health-api.ecomempire.in"  // testing

// export const appUrl = "https://big4health.ecomempire.in/";
// export const appUrl = "https://staging.big4healthapp.com/";

// export const stripeKey = "pk_test_51OmmUdBeAKcnf5VTxIY1FI4JxiI6eJnsO5FRlCoWI4wt0Yr9d7xY3O9sv8KwSQtoxXKD6ZlaHfcJrAfLhTZnZOG3000pVbKatG"

//Live
export const appUrl = "https://big4health.com/";
export const baseUrl = "https://api.big4health.com"
export const stripeKey = "pk_live_51OmmUdBeAKcnf5VTS2jZeKzZDKtg83wFxJFKTKOj6sqVbRaLzQf25D4QGZB4HicHtl1YwsCUPAIcLI6OhbQJMTqS00BWyZ4qKI"

export const ContactUsEmail = "info@big4health.com"

export const appName = "BIG4 Health"
export const config = {
  apiUrl: baseUrl + "/v1",
  imageUrl: baseUrl + '/',
  // fbAppId: "864982481808238",
  // fbAppId: "6818299948190412",
  fbAppId: "300862712579183",
  googleClientId: "65883300767-u72tirqar6vssgp5jfeob06fatu12e1n.apps.googleusercontent.com",
  // googleClientId: "84530574831-680pj90j104h3l0pfqo8cl76ubagd4q2.apps.googleusercontent.com",
  // googleClientId: "522963890633-6fqmn12r2ishatd815c418p4l3sqi8qd.apps.googleusercontent.com",   // job posting
}
export const categories = ["Doctor", "Nurse", "PA", "Nutritionist"];
export const healthCareProvidercategories = ["All", "Doctor", "Nurse", "PA", "Nutritionist"];
// netset aws creds
// export const accessAwsDetail = {
//   awsAccessKey: "AKIAVT3XCR6A3HDLSFA3",
//   awsSecretKey: "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",
//   awsRegion: "us-east-1",
//   awsBucketName: "b4hstorage"
// }
// client aws creds
export const accessAwsDetail = {
  awsAccessKey: "AKIAZXJM6GBDHROADWXS",
  awsSecretKey: "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",
  awsRegion: "us-east-2",
  awsBucketName: "big4healthstorage"
}

// export const accessAwsDetail = {
//   awsAccessKey: "AKIA3XH6443UJS5O3LNJ",
//   awsSecretKey: "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",
//   awsRegion: "ap-northeast-1",
//   awsBucketName: "onlyfantasy"
// }

export const GetVideoThumbnails = async (props) => {
  let imageUrl;
  let videoFile = props.file;
  if (videoFile) {
    await generateVideoThumbnails(videoFile, 1).then(async (thumbs) => {
      var thumb;
      if (Array.isArray(thumbs)) {
        thumb = DataURLtoFile(thumbs[0], "thumbnails.jpeg");
      } else {
        thumb = DataURLtoFile(thumbs, "thumbnails.jpeg");
      }
      let thumbnailImageType = thumb.type.split("/")
      let thumbnailImageName = Math.floor(Date.now() / 1000) + "." + thumbnailImageType[1]
      imageUrl = thumb
    }).catch(err => {
    })
  }
  return imageUrl;
}

export const DataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}